export const environment = {
    production: true,
    envName: 'acc',
    chatAppId: 't9g4yNri',
    googleAnalyticsCode: 'UA-130585201-1',
    apiPath: '/api',
    furbanHome: 'https://acceptance.furban.nl/',
    geoApi: 'https://api.pdok.nl/bzk/locatieserver/search/v3_1',
    geoApiOpenMap: 'https://nominatim.openstreetmap.org/search',
    socket: 'wss://gytkmwz30k.execute-api.eu-central-1.amazonaws.com/development',
    socketToken:
        'E3F288A3601D58C2662959A6F0F011FE7C4E126B569E78830AB9AA25A03A3447',
    shareLinks: {
        facebook:
            'https://www.facebook.com/sharer/sharer.php?u=https://beta.furban.nl/api/social/',
        twitter:
            'https://twitter.com/share?url=https://beta.furban.nl/api/social/',
        linkedin: {
            linkedingSharer:
                'https://www.linkedin.com/shareArticle?mini=true&url=',
            furbanLink: 'https://acceptance.furban.nl/api/social/',
        },
    },
};
