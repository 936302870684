import { Component, OnInit } from '@angular/core';
import 'ol/ol.css';
import Draw from 'ol/interaction/Draw.js';
import Map from 'ol/Map.js';
import View from 'ol/View.js';
import { OSM, Vector as VectorSource, XYZ } from 'ol/source.js';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer.js';
import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import { Modify, Snap } from 'ol/interaction'
import { Geometry, Polygon } from 'ol/geom';
import { ColorConstants, CustomToasterComponent, CustomToasterService, FurbanUtil, MapZoomEnum, PointLatLon, PointXY, ProjectService } from '@furban/utilities';
import Feature from 'ol/Feature';
import { Coordinate } from 'ol/coordinate';
import html2canvas from 'html2canvas';
import { Extent } from 'ol/extent';
import { fromLonLat } from 'ol/proj';

@Component({
	selector: 'furban-open-map',
	templateUrl: './open-map.component.html',
	styleUrls: ['./open-map.component.scss'],
})
export class OpenMapComponent implements OnInit {

	public map!: Map;
	private vectorSource: VectorSource;
	private drawInteraction: Draw;
	private defaultLayer: TileLayer<OSM>;
	private satelliteLayer: TileLayer<any>;
	private currentLayer: TileLayer<any>;
	private mapView: View;
	private readonly MAX_AREA_SIZE = 100000;
	private readonly SATELITE_LAYER_URL = 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}';
	private readonly MAP_COORDINATES = [2775432.472316647, 5760918.582412404];
	private vectorLayer: VectorLayer<Feature<Geometry>>;

	constructor(
		private projectService: ProjectService,
		private customToasterService: CustomToasterService

	) { }

	ngOnInit(): void {
		this.initializeMap();
		this.addInteraction();
		this.initializePolygon();
	}

	public async getMapImage(): Promise<string> {
		const mapElement = document.getElementsByClassName(
			'ol-viewport'
		)[0] as HTMLElement;

		if (mapElement && mapElement.hasChildNodes()) {
			const controlsElement = mapElement.childNodes[2] as HTMLElement;
			controlsElement.setAttribute('data-html2canvas-ignore', 'true');
		}
		const canvas = await html2canvas(mapElement, {
			useCORS: true
		});
		return canvas.toDataURL();
	}

	public redrawPath(): void {
		this.vectorSource.clear();
		this.map.removeInteraction(this.drawInteraction);
		this.parsePath();
	}

	public resetMap(): void {
		this.vectorSource.clear();
	}

	public drawPolygon(): void {
		this.drawInteraction = new Draw({
			source: this.vectorSource,
			type: 'Polygon',
		});
		this.resetMap();
		this.map.addInteraction(this.drawInteraction);
		this.onPolygonDrawned();
	}

	public goToMyCurentGeolocation(): void {
		if (!navigator.geolocation) {
			return;
		}
		navigator.geolocation.getCurrentPosition((position) => {
			const lon = position.coords.longitude;
			const lat = position.coords.latitude;
			this.zoomToPoint(lat, lon);
		});
	}


	public changeLayer(): void {
		this.map.removeLayer(this.currentLayer);
		this.map.removeLayer(this.vectorLayer);
		this.currentLayer = this.currentLayer === this.defaultLayer ? this.satelliteLayer : this.defaultLayer;
		this.map.addLayer(this.currentLayer);
		this.map.addLayer(this.vectorLayer);
	}

	public setMapPosition(lat: number, lon: number): void {
		this.zoomToPoint(lat, lon);
	}

	private initializeMap(): void {
		this.defineMapLayers();
		this.vectorSource = new VectorSource();

		this.mapView = new View({
			center: this.MAP_COORDINATES,
			zoom: MapZoomEnum.DEFAULT,
			maxZoom: MapZoomEnum.MAX
		});

		this.vectorLayer = new VectorLayer({
			source: this.vectorSource,
			style: new Style({
				fill: new Fill({
					color: ColorConstants.redWithTransparency,
				}),
				stroke: new Stroke({
					color: ColorConstants.red,
					width: 2,
				}),
			}),
		});

		this.map = new Map({
			target: 'map',
			layers: [
				this.satelliteLayer,
				this.vectorLayer,
			],
			view: this.mapView,
		});
		this.currentLayer = this.satelliteLayer;
	}

	private addInteraction(): void {
		const modify = new Modify({
			source: this.vectorSource,
		});
		this.map.addInteraction(modify);
		const snap = new Snap({
			source: this.vectorSource,
		});
		this.map.addInteraction(snap);
	}

	private onPolygonDrawned(): void {
		this.drawInteraction.on('drawend', (event) => {
			this.checkDrawnCoordinates(event.feature);
		});
	}


	private checkDrawnCoordinates(feature: Feature): void {
		const polygonGeometry = feature.getGeometry() as Polygon;
		const coordinates = polygonGeometry.getCoordinates()[0];
		const area = polygonGeometry.getArea();
		if (area > this.MAX_AREA_SIZE) {
			this.customToasterService.openCustomToaster(
				CustomToasterComponent,
				'warning',
				'info',
				'admin.map.areaBig',
				2000
			);
			this.resetMap();
			return;
		}

		this.savePolygonAfterDrawing(coordinates);
		this.zoomToPolygon(feature);
	}

	private initializePolygon(): void {
		if (this.projectService.curentDrawing.pathId) {
			this.parsePath();
		} else {
			this.drawPolygon();
		}
	}

	private parsePath(): void {
		this.drawPolygonOnMap(this.projectService.curentDrawing.xYCoordinates);

		if (!this.projectService.curentDrawing.defaultMaterial) {
			this.projectService.curentDrawing.defaultMaterial = 1001;
		}
	}

	private drawPolygonOnMap(pointsOfPolygon: Array<PointXY>): void {
		const exteriorRing = [];

		for (const coordinate of pointsOfPolygon) {
			exteriorRing.push([coordinate.X, coordinate.Y]);
		}

		const polygon = new Polygon([exteriorRing]);
		const polygonFeature = new Feature({
			geometry: polygon
		});

		this.vectorSource.addFeature(polygonFeature);
		this.zoomToPolygon(polygonFeature);
	}

	private savePolygonAfterDrawing(coordinates: Coordinate[]): void {
		const latLonCoordinatesArray = coordinates.map((point: Coordinate) => {
			const pointLatLot = new PointLatLon();
			pointLatLot.Lat = point[0] as number;
			pointLatLot.Lon = point[1] as number;
			return pointLatLot;
		});

		const XYCoordinatesArray = coordinates.map((point: Coordinate) => {
			const pointLatLot = new PointXY();
			pointLatLot.X = point[0] as number;
			pointLatLot.Y = point[1] as number;
			return pointLatLot;
		});

		this.projectService.copyDrawing.latLonCoordinates = latLonCoordinatesArray;
		this.projectService.copyDrawing.xYCoordinates = XYCoordinatesArray;
		const polygonCenter = FurbanUtil.getPolygonCentroid(
			XYCoordinatesArray
		);
		this.projectService.copyDrawing.xCenter = polygonCenter.x;
		this.projectService.copyDrawing.yCenter = polygonCenter.y;
		this.projectService.isUserDrawing = false;
	}

	private zoomToPolygon(polygonFeature: Feature): void {
		if (polygonFeature) {
			const extent: Extent = polygonFeature.getGeometry().getExtent();
			this.map.getView().fit(extent, {
				size: this.map.getSize(),
				padding: [50, 50, 50, 50],
				maxZoom: MapZoomEnum.MAX
			});
		}
	}

	private zoomToPoint(lat: number, lon: number): void {
		const coordinates = fromLonLat([lon, lat]);
		this.mapView.setCenter(coordinates);
		this.mapView.setZoom(MapZoomEnum.MAX);
	}

	private defineMapLayers(): void {
		this.defaultLayer = new TileLayer({
			source: new OSM()
		});

		this.satelliteLayer = new TileLayer({
			source: new XYZ({
				url: this.SATELITE_LAYER_URL,
				crossOrigin: 'anonymous'
			})
		});
	}
}
